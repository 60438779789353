import React, { useEffect, useState } from 'react'
import { Text } from '@components'
import {
  ADS_DATA,
  ADS_PAGINATION_DATA,
  ADS_SEARCH_KEYWORD,
  ADS_HOME_STATE,
  ADS_NO_DATA,
  ADS_PAGINATION,
  EMPTY_ADS_PREVIEW_DATA,
  SPINNER,
  CLEANED_DATA_COUNT,
  HAS_MORE,
  ADS_FORMAT,
  MORE_DATA_LOADER,
  META_ADS_PAGINATION_DATA,
} from '@utils/constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector, useDispatch } from 'react-redux'
import { RequestService } from '@services/requests'
import SearchBar from '@components/GoogleAdsPreview/SearchBar'
import SearchResultsError from '@components/GoogleAdsPreview/ErrorStates/SearchResultsError'
import NoData from '@components/GoogleAdsPreview/ErrorStates/NoData'
import GooglerAdsCard from '@components/GoogleAdsPreview/DataCard'
import { Spin } from 'antd'
import styled from 'styled-components'
import styles from './adsPreviewFeature.module.css'

const AdsPreviewFeature = () => {
  const dispatch = useDispatch()
  const adsDataStore = useSelector(state => state?.adsPreview?.adsPreviewData)
  const dataLoading = useSelector(state => state?.adsPreview.loading)
  const homeState = useSelector(state => state?.adsPreview.homeState)
  const noData = useSelector(state => state?.adsPreview?.adsNoData)
  const adsPaginationUrl = useSelector(state => state?.adsPreview.adsPagination)
  const spinner = useSelector(state => state?.adsPreview.spinner)
  const searchKeyword = useSelector(state => state?.adsPreview?.searchKeyword)
  const cleanedDataCount = useSelector(state => state?.adsPreview.cleanedDataCount)
  const selectedChannel = useSelector(state => state?.adsPreview?.channels)
  const adsFormats = useSelector(state => state?.adsPreview?.formats)
  const hasMore = useSelector(state => state?.adsPreview?.hasMore)
  const [newDataSpin, setNewDataSpin] = useState(false)
  const fetchMoreData = async () => {
    dispatch({ type: MORE_DATA_LOADER, payload: true })
    if (!hasMore) {
      return
    }

    const googleData = {
      next_url: adsPaginationUrl,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      channels: selectedChannel,
      search_term: searchKeyword,
    }
    const metaData = {
      next_url_meta: adsPaginationUrl,
      creative_format: adsFormats.toString().toLowerCase().replace(/s$/, ''),
      channels: selectedChannel,
      search_term: searchKeyword,
    }
    if (selectedChannel === 'Google' || selectedChannel === 'G') {
      try {
        dispatch({ type: HAS_MORE, payload: true })
        const response = await RequestService.post('/ads-preview/competitors/', googleData)
        const formattedData = response?.data
        dispatch({ type: MORE_DATA_LOADER, payload: false })
        dispatch({
          type: ADS_PAGINATION,
          payload: formattedData?.google_ads_result?.google_ads[0]?.serpapi_pagination?.next,
        })
        if (formattedData?.google_ads_result.google_ads[0].serpapi_pagination?.next) {
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }
        if (adsFormats === 'Videos') {
          dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedDataCount + 20 })
        } else {
          dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedDataCount + 100 })
        }
        dispatch({
          type: ADS_PAGINATION_DATA,
          payload: formattedData?.google_ads_result?.google_ads[0]?.ad_creatives,
        })
        const newAdsData = formattedData?.google_ads_result?.google_ads[0]?.ad_creatives

        if (newAdsData.length === 0 || adsPaginationUrl.length > 0) {
          // sethasMore(false)
        } else {
          dispatch({
            type: ADS_DATA,
          })
        }
        if (newAdsData.length > 0) {
          dispatch({
            type: ADS_DATA,
          })
        }
        dispatch({ type: SPINNER, payload: false })
      } catch (e) {
        dispatch({ type: MORE_DATA_LOADER, payload: false })
        dispatch({ type: SPINNER, payload: false })
        // notify('API Request failed', 'error')
      }
    } else if (selectedChannel === 'Meta') {
      try {
        dispatch({ type: HAS_MORE, payload: true })
        setNewDataSpin(true)
        const response = await RequestService.post('/ads-preview/competitors/', metaData)
        setNewDataSpin(false)
        const formattedData = response?.data
        dispatch({ type: MORE_DATA_LOADER, payload: false })
        dispatch({
          type: META_ADS_PAGINATION_DATA,
        })
        if (formattedData?.meta_ads.facebook_ads[0]?.paging?.next) {
          dispatch({
            type: ADS_PAGINATION,
            payload: formattedData.meta_ads.facebook_ads[0].paging.next,
          })
        }

        if (formattedData?.meta_ads.facebook_ads[0]?.paging?.next) {
          dispatch({
            type: HAS_MORE,
            payload: true,
          })
        } else {
          dispatch({
            type: HAS_MORE,
            payload: false,
          })
        }

        dispatch({ type: CLEANED_DATA_COUNT, payload: cleanedDataCount + 20 })
        dispatch({
          type: ADS_PAGINATION_DATA,
          payload: formattedData?.meta_ads?.facebook_ads[0]?.data,
        })
        if (formattedData?.meta_ads?.facebook_ads[0]?.data) {
          dispatch({
            type: META_ADS_PAGINATION_DATA,
            payload: formattedData?.meta_ads?.facebook_ads[0]?.data,
          })
        }

        const newAdsData = formattedData?.meta_ads?.facebook_ads[0]?.data

        if (newAdsData.length === 0 || adsPaginationUrl.length > 0) {
          // sethasMore(false)
        } else {
          dispatch({
            type: ADS_DATA,
          })
        }
        if (newAdsData.length > 0) {
          dispatch({
            type: ADS_DATA,
          })
        }

        dispatch({ type: SPINNER, payload: false })
      } catch (e) {
        setNewDataSpin(false)
        dispatch({ type: SPINNER, payload: false })
        dispatch({ type: MORE_DATA_LOADER, payload: false })
        // notify('API Request failed', 'error')
      }
    }
  }

  useEffect(() => {
    dispatch({
      type: ADS_DATA,
      payload: [],
    })
    dispatch({ type: EMPTY_ADS_PREVIEW_DATA })
    dispatch({ type: ADS_FORMAT, payload: '' })
    dispatch({ type: ADS_SEARCH_KEYWORD, payload: '' })
    dispatch({ type: ADS_HOME_STATE, payload: true })
    dispatch({ type: ADS_NO_DATA, payload: false })
    dispatch({ type: HAS_MORE, payload: false })
    dispatch({ type: MORE_DATA_LOADER, payload: false })
    dispatch({
      type: ADS_PAGINATION,
      payload: '',
    })
    dispatch({
      type: SPINNER,
      payload: false,
    })

    // eslint-disable-next-line
  }, [])
  const ButtonContainer = styled.span`
    position: fixed;
    bottom: 32px;
    right: 32px;
    transform: translate(50%, 50%);
    align-items: center;
    height: 32px;
    width: 32px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    opacity: 1;
    background: #f16434;
    border-radius: 5rem;
    // transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
    display: 'flex';
    // &:hover {
    //   opacity: 1;
    // }
  `
  const [showButton, setShowButton] = useState(false)
  const scrollToTop = () => {
    const targetDiv = document.getElementById('scrollDiv')
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' })
      setShowButton(false)
    }
  }

  window.addEventListener('wheel', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  window.addEventListener('keydown', event => {
    const position = document.getElementById('scrollableDiv')
    const scrollPosition = position.scrollTop
    if (scrollPosition <= 200) {
      setShowButton(false)
    } else {
      setShowButton(true)
    }
  })

  return (
    <>
      <div id="scrollSection">
        <Text isBold style={{ fontSize: 22, color: '#F16434' }}>
          Creative Library
        </Text>
      </div>
      <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <SearchBar />
      </div>

      {homeState ? <NoData /> : ''}
      {noData && !dataLoading ? <SearchResultsError /> : ''}

      {spinner ? (
        <div
          style={{
            marginTop: 150,
            textAlign: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {selectedChannel === 'Google' || selectedChannel === 'G' ? (
            <>
              {adsDataStore?.length > 0 && !dataLoading ? (
                <Text size={20} className={styles.searchResultCount}>
                  {adsFormats === 'Videos' ? (
                    <>
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {cleanedDataCount >= 20 ? ` ${cleanedDataCount}+` : cleanedDataCount} ads
                      </span>{' '}
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: 'bold' }}>
                        {cleanedDataCount >= 100 ? ` ${cleanedDataCount}+` : cleanedDataCount} ads
                      </span>{' '}
                    </>
                  )}
                  match your search criteria
                </Text>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {adsDataStore?.length > 0 && !dataLoading ? (
                <Text size={20} className={styles.searchResultCount}>
                  <span style={{ fontWeight: 'bold' }}>
                    {cleanedDataCount >= 20 ? ` ${cleanedDataCount}+` : cleanedDataCount} ads
                  </span>{' '}
                  match your search criteria
                </Text>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}

      {/* End of Counter to display number of search results */}

      {/* Start of Pagination */}
      <div
        className={styles}
        // id="scrollableDiv"
        style={{ overflow: 'auto', marginTop: 50 }}
      >
        <div>
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            // className="js-scroll-list"
            dataLength={adsDataStore?.length}
            hasMore={hasMore}
            next={fetchMoreData}
            loader={
              newDataSpin ? (
                <div
                  style={{
                    marginTop: 150,
                    textAlign: 'center',
                  }}
                >
                  <Spin />
                </div>
              ) : (
                ''
              )
            }
            scrollableTarget="scrollableDiv"
          >
            <div className={styles.grid}>
              {
                // eslint-disable-next-line
                adsDataStore?.length > 0 ? (
                  <div className={styles.gridItem}>
                    <GooglerAdsCard adsDatas={adsDataStore} />
                  </div>
                ) : (
                  // ))
                  ''
                )
              }
            </div>
            {/* </Row> */}
          </InfiniteScroll>
        </div>
      </div>
      {adsDataStore.length > 0 && showButton ? (
        <ButtonContainer onClick={scrollToTop}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 19V5M5 12l7-7 7 7" />
          </svg>
        </ButtonContainer>
      ) : (
        ''
      )}

      {/* End of Pagination */}
    </>
  )
}

export default AdsPreviewFeature
