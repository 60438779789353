import { useCallback, useEffect, useState } from 'react'

const prefix = process.env.NODE_ENV === 'development' ? 'DEV_' : ''
const utmStoreName = `${prefix}ads_grader_saved_utms`

export function useUtmParamsStore(search) {
  const [utms, setUtms] = useState(null)

  function storeSet(_utms) {
    localStorage.setItem(utmStoreName, JSON.stringify(_utms))
  }

  function storeGet() {
    const storedUtms = localStorage.getItem(utmStoreName)

    if (storedUtms) return JSON.parse(storedUtms)

    return null
  }

  function storeClear() {
    localStorage.clear(utmStoreName)
  }

  const setUtmFromParams = useCallback(() => {
    const params = new URLSearchParams(search)
    // console.log('🚀 ~ setUtmFromParams ~ params:', params)
    const parsedUtms = {
      utm_source: params.get('utm_source') || '',
      utm_medium: params.get('utm_medium') || '',
      utm_campaign: params.get('utm_campaign') || '',
      utm_term: params.get('utm_term') || '',
      utm_content: params.get('utm_content') || '',
      utm_id: params.get('utm_id') || '',
    }
    // console.log('🚀 ~ setUtmFromParams ~ parsedUtms:', parsedUtms)

    const utmsWithValue = Object.values(parsedUtms).filter(value => !!value)

    if (utmsWithValue.length > 0) {
      setUtms(parsedUtms)
      storeSet(parsedUtms)
    }
  }, [search])

  useEffect(() => {
    const storedUtms = storeGet()

    if (storedUtms) {
      setUtms(storedUtms)
    } else {
      setUtmFromParams()
    }
  }, [setUtmFromParams])

  return { utms, clearUtmStore: storeClear }
}
