import Text from '@components/Text/text'
import React, { useEffect, useState } from 'react'
import { Avatar, Col, Collapse, List, Row, Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { LS_KEY, AG_AC_TOKEN } from '@utils/constants'
import { notify } from '@utils/notify'
import { baseURL, RequestService } from '@services/requests'
import { DownOutlined } from '@ant-design/icons'
import shareIcon from '@images/AdsPreview/shareIcon.png'
import informationIcon from '../../../images/AdsPreview/informationIcon.svg'
import styles from './modalAboutTable.module.css'

const ModalAboutTable = ({ adsData }) => {
  const { Panel } = Collapse
  const [data, setData] = useState([])
  const user = JSON.parse(localStorage.getItem(LS_KEY))
  const accessToken = user.access_token

  const [loading, setLoading] = useState(true)
  const startDate = useSelector(state => state?.adsPreview?.startDate)
  const endDate = useSelector(state => state?.adsPreview?.endDate)
  // Dates may be required later
  const startYear = startDate?.slice(0, 4)
  const endYear = endDate?.slice(0, 4)
  const startMonth = startDate?.slice(4, 6)
  const endMonth = endDate?.slice(4, 6)
  const startDay = startDate?.slice(6, 8)
  const endDay = endDate?.slice(6, 8)
  const formattedStartDate = new Date(`${startYear}-${startMonth}-${startDay}`)
  const formattedEndDate = new Date(`${endYear}-${endMonth}-${endDay}`)
  const getMonthString = monthNumber => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return months[monthNumber - 1]
  }
  const startMonthFormatted = getMonthString(formattedStartDate?.getMonth() + 1)
  const endMonthFormatted = getMonthString(formattedEndDate?.getMonth() + 1)
  const displayStartDate = `${startMonthFormatted} ${startDay}, ${startYear}`
  const displayEndDate = `${endMonthFormatted} ${endDay}, ${endYear}`

  const handleModalApi = async () => {
    setLoading(true)
    // eslint-disable-next-line
    const targetDomain = adsData?.target_domain
    try {
      const response = await RequestService.get(
        `/ads-preview/competitors/?search_term=${targetDomain}`
      )
      const formattedData = response?.data
      setData(formattedData?.google_ads[0])
      setLoading(false)
    } catch {
      setLoading(false)
      // notify('API Request failed', 'error')
    }
  }

  useEffect(() => {
    handleModalApi()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* Start of About the Ad Collapse */}
      <div id="ModalCollapseSetting">
        <Collapse
          expandIcon={({ isActive }) => (
            <div className={styles.headingIcon}>
              <DownOutlined rotate={isActive ? 180 : 360} />{' '}
            </div>
          )}
          expandIconPosition="right"
          defaultActiveKey={['1']}
          ghost
        >
          <Panel
            header={
              <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                About the Ad
              </Text>
            }
            key="1"
          >
            {/* <Text isBold style={{ marginTop: '10px', textAlign: 'center' }}>
              No DATA FOUND
            </Text> */}
            <Row>
              <Col span={24} className={styles.childCollapse}>
                <div style={{ marginLeft: '20px', marginBottom: 25 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Advertiser
                    </Text>
                    <div style={{ marginLeft: '6px', position: 'relative' }} id="area">
                      {/* <Tooltip
                        getPopupContainer={() => document.getElementById('area')}
                        placement="bottom"
                        title="Display whatever Saulo says here"
                      > */}
                      {/* <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      /> */}
                      {/* </Tooltip> */}
                    </div>
                  </div>
                  <div style={{ display: 'flex', marginTop: '9px' }}>
                    <Text isBold size={14}>
                      {adsData.advertiser}
                    </Text>
                    {/* <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={shareIcon}
                        alt="card-img"
                        width={14}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-10px' }}
                      />
                    </div> */}
                  </div>
                </div>
              </Col>
              {/* Dates may be required later */}
              {/* <Col span={7} className={styles.childCollapse1}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Date Range
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>
                      {startDate.length > 0
                        ? `${displayStartDate} – ${displayEndDate}`
                        : 'Date not available'}
                    </Text>
                  </div>
                </div>
              </Col> */}
              {/* <Col span={12} className={styles.childCollapse2}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Beneficiary
                    </Text>
                    <div style={{ marginLeft: '6px', position: 'relative' }} id="area"> */}
              {/* <Tooltip
                        getPopupContainer={() => document.getElementById('area')}
                        placement="bottom"
                        title="Display whatever Saulo says here"
                      > */}
              {/* <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      /> */}
              {/* </Tooltip> */}
              {/* </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>{adsData.advertiser}</Text>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Panel>
        </Collapse>
      </div>
      {/* End of About the Ad Collapse */}
      {/* Start of Organic Results Collapse */}
      <div id="ModalCollapseSetting" style={{ marginTop: '32px' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className={styles.headingIcon}>
              <DownOutlined rotate={isActive ? 180 : 360} />{' '}
            </div>
          )}
          expandIconPosition="right"
          defaultActiveKey={['3']}
          ghost
        >
          <Panel
            header={
              <div className={styles.tableHeader}>
                <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                  Organic Results
                </Text>

                {/* <div className={styles.tableImg}>
                  <img src={dotIcon} alt="card-img" />
                </div>
                <Text size={24} isBold style={{ display: 'flex', alignItems: 'center' }}>
                  34%
                </Text>
                <div className={styles.tableTag1}>
                  <Text size={14} isBold style={{ color: '#E53435', textAlign: 'center' }}>
                    Bad
                  </Text>
                </div>
                <div className={styles.tableImg}>
                  <img
                    src={informationIcon}
                    alt="card-img"
                    width={8}
                    height={14}
                    style={{ cursor: 'pointer' }}
                  />
                </div> */}
              </div>
            }
            key="3"
          >
            {/* <Text isBold style={{ marginTop: '10px', textAlign: 'center' }}>
              No DATA FOUND
            </Text> */}
            <div
              // className={styles.scrollableDiv}
              id="scrollableDiv2"
              // style={{ overflow: 'auto' }}
              // style={{ overflow: 'auto' }}
            >
              <List
                itemLayout="horizontal"
                loading={loading}
                dataSource={data.organic_results}
                style={{ marginLeft: 10 }}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.favicon} />}
                      title={
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      }
                    />
                  </List.Item>
                )}
                locale={{
                  emptyText: (
                    <div style={{ marginTop: 16 }}>
                      <svg
                        className="ant-empty-img-simple"
                        width="64"
                        height="41"
                        viewBox="0 0 64 41"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                          <ellipse
                            className="ant-empty-img-simple-ellipse"
                            cx="32"
                            cy="33"
                            rx="32"
                            ry="7"
                          />
                          <g className="ant-empty-img-simple-g" fillRule="nonzero">
                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                            <path
                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                              className="ant-empty-img-simple-path"
                            />
                          </g>
                        </g>
                      </svg>
                      <p>No Results Available</p>
                    </div>
                  ),
                }}
              />
            </div>

            {/* <Row>
              <Col span={4} className={styles.tableLeft}>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Impressions
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      23.57k
                    </Text>
                    <div className={styles.tableTagGood}>
                      <Text
                        size={12}
                        isBold
                        style={{
                          color: 'var(--graphs-charts-dark-green, #00A862)',
                          marginTop: 'auto',
                        }}
                      >
                        Good
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Conversions
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      15.8k
                    </Text>
                    <div className={styles.tableTagMedium}>
                      <Text
                        size={12}
                        isBold
                        style={{
                          color: 'var(--graphs-charts-dark-yellow, #E89900)',
                          textAlign: 'center',
                          marginTop: 'auto',
                        }}
                      >
                        Medium
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Visitbility
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      23%
                    </Text>
                    <div className={styles.tableTagBad}>
                      <Text
                        size={12}
                        isBold
                        style={{ color: '#E53435', textAlign: 'center', marginTop: 'auto' }}
                      >
                        Bad
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Ad Position
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      57
                    </Text>
                    <div className={styles.tableTagBad}>
                      <Text
                        size={12}
                        isBold
                        style={{ color: '#E53435', textAlign: 'center', marginTop: 'auto' }}
                      >
                        Bad
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={20}>
                <div className={styles.tableTitle}>
                  <Text size={16} isBold>
                    Demographic Reach
                  </Text>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={informationIcon}
                      alt="card-img"
                      width={8}
                      height={14}
                      style={{ cursor: 'pointer', marginTop: -7 }}
                    />
                  </div>
                </div>
                <div id="ads-preview-modal-table">
                  <Table
                    style={{ marginTop: 21 }}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </Col>
            </Row> */}
          </Panel>
        </Collapse>
      </div>
      {/* End of Organic Results Collapse */}
      {/* Start of Related Queries Collapse */}
      <div id="ModalCollapseSetting" style={{ marginTop: '32px' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className={styles.headingIcon}>
              <DownOutlined rotate={isActive ? 180 : 360} />{' '}
            </div>
          )}
          expandIconPosition="right"
          defaultActiveKey={['2']}
          ghost
        >
          <Panel
            header={
              <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                Related Queries
              </Text>
            }
            key="2"
          >
            <List
              itemLayout="horizontal"
              loading={loading}
              dataSource={data.related_questions}
              style={{ marginLeft: 10 }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta title={item.question} description={item.snippet} />
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <div style={{ marginTop: 16 }}>
                    <svg
                      className="ant-empty-img-simple"
                      width="64"
                      height="41"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                        <ellipse
                          className="ant-empty-img-simple-ellipse"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        />
                        <g className="ant-empty-img-simple-g" fillRule="nonzero">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            className="ant-empty-img-simple-path"
                          />
                        </g>
                      </g>
                    </svg>
                    <p>No Results Available</p>
                  </div>
                ),
              }}
            />

            {/* <Row>
              <Col span={6} className={styles.childCollapse3}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Age
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>18-65+ years old</Text>
                  </div>
                </div>
              </Col>
              <Col span={6} className={styles.childCollapse3}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Gender
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>All</Text>
                  </div>
                </div>
              </Col>
              <Col span={6} className={styles.childCollapse3}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Language
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>English</Text>
                  </div>
                </div>
              </Col>
              <Col span={6}>
                <div style={{ marginLeft: '20px', marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginTop: '21px' }}>
                    <Text isBold size={16} className={styles.divContent}>
                      Location
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <Text size={16}>United States</Text>
                  </div>
                </div>
              </Col>
            </Row> */}
          </Panel>
        </Collapse>
      </div>
      {/* End of Related Queries Collapse */}

      {/* Start of Search Queries Collapse */}
      {/* <div id="ModalCollapseSetting" style={{ marginTop: '32px' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <div className={styles.headingIcon}>
              <DownOutlined rotate={isActive ? 180 : 360} />{' '}
            </div>
          )}
          expandIconPosition="right"
          defaultActiveKey={['4']}
          ghost
        >
          <Panel
            header={
              <div className={styles.tableHeader}>
                <Text size={18} isBold style={{ display: 'flex', alignItems: 'center' }}>
                  Search Queries
                </Text> */}

      {/* <div className={styles.tableImg}>
                  <img src={dotIcon} alt="card-img" />
                </div>
                <Text size={24} isBold style={{ display: 'flex', alignItems: 'center' }}>
                  34%
                </Text>
                <div className={styles.tableTag1}>
                  <Text size={14} isBold style={{ color: '#E53435', textAlign: 'center' }}>
                    Bad
                  </Text>
                </div>
                <div className={styles.tableImg}>
                  <img
                    src={informationIcon}
                    alt="card-img"
                    width={8}
                    height={14}
                    style={{ cursor: 'pointer' }}
                  />
                </div> */}
      {/* </div>
            }
            key="4"
          >
            <List
              itemLayout="horizontal"
              loading={loading}
              dataSource={data.related_searches}
              style={{ marginLeft: 10 }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <a href={item?.link} target="_blank" rel="noreferrer">
                        {item?.query}
                      </a>
                    }
                  />
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <div style={{ marginTop: 16 }}>
                    <svg
                      className="ant-empty-img-simple"
                      width="64"
                      height="41"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                        <ellipse
                          className="ant-empty-img-simple-ellipse"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        />
                        <g className="ant-empty-img-simple-g" fillRule="nonzero">
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            className="ant-empty-img-simple-path"
                          />
                        </g>
                      </g>
                    </svg>
                    <p>No Results Available</p>
                  </div>
                ),
              }}
            /> */}

      {/* <Row>
              <Col span={4} className={styles.tableLeft}>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Impressions
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      23.57k
                    </Text>
                    <div className={styles.tableTagGood}>
                      <Text
                        size={12}
                        isBold
                        style={{
                          color: 'var(--graphs-charts-dark-green, #00A862)',
                          marginTop: 'auto',
                        }}
                      >
                        Good
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Conversions
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      15.8k
                    </Text>
                    <div className={styles.tableTagMedium}>
                      <Text
                        size={12}
                        isBold
                        style={{
                          color: 'var(--graphs-charts-dark-yellow, #E89900)',
                          textAlign: 'center',
                          marginTop: 'auto',
                        }}
                      >
                        Medium
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Visitbility
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      23%
                    </Text>
                    <div className={styles.tableTagBad}>
                      <Text
                        size={12}
                        isBold
                        style={{ color: '#E53435', textAlign: 'center', marginTop: 'auto' }}
                      >
                        Bad
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.tableLeftContainer}>
                  <div className={styles.tableLeftContainer2}>
                    <Text isBold size={16} className={styles.divContent}>
                      Ad Position
                    </Text>
                    <div style={{ marginLeft: '6px' }}>
                      {' '}
                      <img
                        src={informationIcon}
                        alt="card-img"
                        width={8}
                        height={14}
                        style={{ cursor: 'pointer', marginTop: '-7px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.tableLeftContainer3}>
                    <Text size={16} style={{ display: 'flex', alignItems: 'center' }}>
                      57
                    </Text>
                    <div className={styles.tableTagBad}>
                      <Text
                        size={12}
                        isBold
                        style={{ color: '#E53435', textAlign: 'center', marginTop: 'auto' }}
                      >
                        Bad
                      </Text>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={20}>
                <div className={styles.tableTitle}>
                  <Text size={16} isBold>
                    Demographic Reach
                  </Text>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={informationIcon}
                      alt="card-img"
                      width={8}
                      height={14}
                      style={{ cursor: 'pointer', marginTop: -7 }}
                    />
                  </div>
                </div>
                <div id="ads-preview-modal-table">
                  <Table
                    style={{ marginTop: 21 }}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </Col>
            </Row> */}
      {/* </Panel>
        </Collapse>
      </div> */}
      {/* End of Search Queries Collapse */}
    </>
  )
}

export default ModalAboutTable
