/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Collapse, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  AD_RELEVANCE_SECTION,
  CLICK_THROUGH_RATE_SECTION,
  COST_EVALUATION_SECTION,
  GOOGLE_ADS_BEST_PRACTICES_SECTION,
  IMPRESSION_SHARE_SECTION,
  KEYWORDS_MATCH_TYPE_SECTION,
  PERFORMANCE_SECTION,
  QUALITY_SCORE_SECTION,
  WASTED_SPEND_SECTION,
  ADS_BEDGET_COST_CACULATOR,
  ADS_PERFORMANCE_GRADE,
} from 'utils/constants'
import tokenService from '@services/tokenService'
import { useTranslation } from 'react-i18next'
import Calculator from 'pages/calculator'
import AdsPreview from 'pages/google-ads-preview'
import { paginatedNext, updateCurrentReport } from 'store/actions'
import Benchmark from '@pages/benchmark'
import LearningCenter from '@pages/learning-center'
import AgAlertsPage from 'pages/ag-alerts'
import { LS_KEY, AG_AC_TOKEN } from '@utils/constants'
import { notify } from '@utils/notify'
import CustomersSelect from '@components/Select/customersSelect'
import { fetchRoleInfo } from '@services/fetchRoleInfo'
import SampleReport from '@components/ReportPageTemporary/sampleReport'
import Questionnaire from '@components/popover/popover'
import { fetchQuestionnaireQuestions } from '@services/fetchQuestionnaireQuestions'
import AlertIcon from '@images/Alert.png'
import CampaignIcon from '@images/CampaignIcon.png'

import { RequestService } from 'services/requests'
import { scrollToElement, sortByCreatedAt } from '../../utils/helperFunctions'
import ReportPageTemporary from '../../components/ReportPageTemporary/index'
import { fetchReports } from '../../services/fetchReports'
import reportImg from '../../images/report.png'
import consultingImg from '../../images/consulting.svg'
import reportsIcon from '../../images/Sidebar/Reports.png'
import benchmarksIcon from '../../images/Sidebar/Benchmarks.png'
import calculatorIcon from '../../images/Sidebar/Calculator.png'
import consultingIcon from '../../images/Sidebar/Consulting Services.png'
import learningIcon from '../../images/Sidebar/LearningCenter.png'
import creativeIcon from '../../images/Sidebar/Creative.png'

import styles from './dashboard.module.scss'
import ReportHeader from '../../components/Report/ReportHeader'
import SideDrawerCollapse from '../../components/SideDrawerCollapse'
// import { pathname } from 'pages/report-demo/demo-data'

const { Panel } = Collapse

const generateRoles = ['Admin', 'Account Manager', 'Owner']

const Dashboard = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('term') &&
    currentURL.includes('content') &&
    currentURL.includes('source') &&
    currentURL.includes('campaign')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const customer = useSelector(state => state.customer)
  const updatedReport = useSelector(state => state?.report)
  const isGenerateReport = useSelector(state => state?.generateReport)
  const sampleReport = useSelector(state => state?.sampleReport?.sampleReport)
  const [selectedTab, setSelectedTab] = useState('')
  const [reports, setReports] = useState([])
  const [currentReport, setCurrentReport] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedUserId, setSelectedUserId] = useState(customer?.id ?? '')
  const [showGenerateButton, setShowGenerateButton] = useState(false)
  const [generateReportLoading, setGenerateReportLoading] = useState(false)
  const [currentSection, setCurrentSection] = useState(null)
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false)
  const [isBenchmarkOpen, setIsBenchmarkOpen] = useState(false)
  const [isAdsPreviewOpen, setIsAdsPreviewOpen] = useState(false)
  const [isLearningCenterOpen, setIsLearningCenterOpen] = useState(false)
  const [questionnaireData, setQuestionnaireData] = useState([])
  const [allQuestionnaireData, setAllQuestionnaireData] = useState([])
  const [tempTest, setTempTest] = useState(true)
  const [open, setOpen] = useState(['1'])
  const [campaignOpen, setCampaignOpen] = useState([''])

  const [trueAnswered, setTrueAnswered] = useState([])

  const [loadError, setLoadError] = useState(false)

  const token = JSON.parse(localStorage.getItem(LS_KEY))?.access_token
  const history = useHistory()
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const dispatch = useDispatch()

  const [isAlert, setIsAlert] = useState(false)
  const [pannelItemClick, setIpannelItemClick] = useState('')
  const accessToken = tokenService.getLocalAccessToken()
  const sections = [
    { label: `${t('report.adsperformancegrade')}`, key: ADS_PERFORMANCE_GRADE },
    { label: `${t('report.performanceMetrics')}`, key: PERFORMANCE_SECTION },
    { label: `${t('report.tabs.costEvolution.title')}`, key: COST_EVALUATION_SECTION },
    { label: `${t('report.tabs.spend.title')}`, key: WASTED_SPEND_SECTION },
    { label: `${t('report.tabs.adRelevance.title')}`, key: AD_RELEVANCE_SECTION },
    { label: `${t('report.tabs.qualityScore.title')}`, key: QUALITY_SCORE_SECTION },
    { label: `${t('report.tabs.adComparisons.ctr')}`, key: CLICK_THROUGH_RATE_SECTION },
    { label: `${t('report.tabs.keywordsMatchType.title')}`, key: KEYWORDS_MATCH_TYPE_SECTION },
    { label: `${t('report.tabs.impShare.title')}`, key: IMPRESSION_SHARE_SECTION },
    { label: `${t('report.tabs.googleAdsBest.title')}`, key: GOOGLE_ADS_BEST_PRACTICES_SECTION },
    // { label: `${t('report.tabs.adsCalculator')}`, key: ADS_BEDGET_COST_CACULATOR },
  ]
  const getUniqueIDFromURL = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const reportId = url.searchParams.get('reportId')
    const customerId = url.searchParams.get('customerId')
    return { reportId, customerId }
  }
  useEffect(() => {
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessTokens = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    if (inviteFormEmail) {
      if (accessTokens) {
        if (userEmail === inviteFormEmail) {
          const { reportId, customerId } = getUniqueIDFromURL()
          if ((reportId, customerId)) {
            localStorage.setItem('copied_report_unique_id', reportId)
            localStorage.setItem('copied_report_customer_id', customerId)
          }
          // history.push('/report-dashboard')
          history.push('/dashboard')
        } else {
          history.push('/report-dashboard')
        }
      } else {
        // history.push('/report-dashboard')
        // User is not logged in, show login options
      }
    }

    // eslint-disable-next-line
  }, [history])
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const [firstTimeRenderflag, setFirstTimeRenderflag] = useState(true)

  useEffect(() => {
    if (token && customer?.id && firstTimeRenderflag) {
      setIsLoading(true)
      setReports([])
      setCurrentReport(null)
      setCurrentSection(null)
      fetchReports(customer?.id)
        .then(res => {
          setShowGenerateButton(false)
          setIsLoading(false)
          if (res.status === 200) {
            const sortedData = sortByCreatedAt(res?.data?.results)
            setReports(sortedData)
            setSelectedTab('')
            setLoadError(false)
            setTempTest(false)
            if (sortedData?.length) {
              fetchRoleInfo(selectedUserId).then(r => {
                if (generateRoles.includes(r?.data?.role?.name) || !r?.data?.is_invited_user) {
                  setShowGenerateButton(true)
                }
              })
            }
          } else if (res.status >= 500) {
            setSelectedTab('')
            setLoadError(true)
            // notify(`Your Ad's account does not have enough data.`, 'error')
            // notify(t('notification.unableToLoadData'), 'error')
          } else {
            // console.log('reports error~: ', res)
          }
        })
        .catch(error => {
          setIsLoading(false)
          // console.log(error)
        })
      setFirstTimeRenderflag(false)
    } else if (!accessToken) {
      history.push('/login')
    }
    // eslint-disable-next-line
  }, [token, history, customer])
  useEffect(() => {
    if (reports?.length) {
      setSelectedTab(reports[0]?.id)
      setCurrentReport(reports[0])
    }
  }, [reports])
  useEffect(() => {
    if (sampleReport === true) {
      setIsLoading(false)
    }
  }, [sampleReport])
  useEffect(() => {
    if (updatedReport) {
      setCurrentReport(updatedReport)
    }
  }, [updatedReport])

  const handleSelectUser = customerId => {
    setSelectedUserId(customerId)
  }
  // const handleGenerateReport = async () => {
  //   const parentCustomerId = getParentCustomerId(customer.resource_name)
  //   console.log('generate', customer.id, parentCustomerId)
  //   setGenerateReportLoading(true)
  //   await generateReportForCustomer(String(customer?.id), parentCustomerId).then(res => {
  //     setGenerateReportLoading(false)
  //     if (res.status >= 200 && res.status <= 299) {
  //       notify('Report generated successfully', 'success')
  //       setReports(pre => [...pre, res.data])
  //     } else if (res.status >= 500) {
  //       notify('Internal server error!', 'error')
  //     } else {
  //       notify('Something went wrong', 'error')
  //     }
  //   })
  // }

  // const currentPath = window.location.pathname
  // useEffect(() => {
  //   if (currentPath === '/dashboard/learning-center') {
  //     setOpen([])
  //     setIsLearningCenterOpen(true)
  //     setIsCalculatorOpen(false)
  //     setIsBenchmarkOpen(false)
  //   } else if (currentPath === '/dashboard/calculator') {
  //     setOpen([])
  //     setIsCalculatorOpen(true)
  //     setIsBenchmarkOpen(false)
  //     setIsLearningCenterOpen(false)
  //   } else if (currentPath === '/dashboard/benchmarks') {
  //     setOpen([])
  //     setIsBenchmarkOpen(true)
  //     setIsCalculatorOpen(false)
  //     setIsLearningCenterOpen(false)
  //   } else if (currentPath === '/dashboard') {
  //     setIsCalculatorOpen(false)
  //     setIsBenchmarkOpen(false)
  //     setIsLearningCenterOpen(false)
  //   }
  // }, [currentPath])

  const handleSectionClick = sectionId => {
    setIsCalculatorOpen(false)
    setCampaignOpen([])
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
    setIsLearningCenterOpen(false)
    history.push('/dashboard')
    setTimeout(() => {
      const el = document.getElementById(sectionId)
      scrollToElement(el)
      setCurrentSection(sectionId)
    }, 500)
  }
  const handleBenchMark = e => {
    e.preventDefault()
    setOpen([])
    setCampaignOpen([])
    history.push('/dashboard/benchmarks')
    setIsBenchmarkOpen(true)
    setIsCalculatorOpen(false)
    setIsAdsPreviewOpen(false)
    setIsLearningCenterOpen(false)
  }
  const handleCalculator = e => {
    e.preventDefault()
    setOpen([])
    setCampaignOpen([])

    history.push('/dashboard/calculator')
    setIsCalculatorOpen(true)
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
  }
  const handleAdsPreview = e => {
    // e.preventDefault()
    setOpen([])
    history.push('/dashboard/creative-library')
    setIsAdsPreviewOpen(true)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsLearningCenterOpen(false)
  }
  const handleLearningCenter = e => {
    e.preventDefault()
    setOpen([])
    history.push('/dashboard/learning-center')
    setIsLearningCenterOpen(true)
    setIsCalculatorOpen(false)
    setIsBenchmarkOpen(false)
    setIsAdsPreviewOpen(false)
  }

  const currentPath = window.location.pathname
  useEffect(() => {
    if (currentPath === '/dashboard') {
      setIsLearningCenterOpen(false)
      setIsCalculatorOpen(false)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
    } else if (currentPath === '/dashboard/calculator') {
      setOpen([])
      setIsLearningCenterOpen(false)
      setIsCalculatorOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
    } else if (currentPath === '/dashboard/benchmarks') {
      setOpen([])
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(true)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/creative-library') {
      setOpen([])
      setIsAdsPreviewOpen(true)
      setIsLearningCenterOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/learning-center') {
      setOpen([])
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/learning-center/search-feature') {
      setOpen([])
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/learning-center/knowledge') {
      setOpen([])
      setIsLearningCenterOpen(true)
      setIsAdsPreviewOpen(false)
      setIsBenchmarkOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/learning-center/videos') {
      setOpen([])
      setIsLearningCenterOpen(true)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
    } else if (currentPath === '/dashboard/learning-center/trainings') {
      setOpen([])
      setIsLearningCenterOpen(true)
      setIsBenchmarkOpen(false)
      setIsAdsPreviewOpen(false)
      setIsCalculatorOpen(false)
    }
  }, [currentPath])
  const handleOpenConsulting = async () => {
    try {
      const { data } = await RequestService.get('/accounts/user-lead/')
      // Base URL with common parameters
      let url =
        'https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting'
      // Add additional parameters if data is available
      if (data) {
        // url += `&currency=${reportPayload?.currency}`
        url += `&cf-phone=${data.phone}`
        url += `&cf-email=${data.email}`
        // url += `&cf-budget=${data.budget}`
        url += `&cf-fname=${data.first_name}`
        url += `&cf-lname=${data.last_name}`
        url += `&cf-url=${data.url}`
        url += `&cf-industry=${encodeURIComponent(data.industry)}`
      }

      // Open the URL in a new window
      window.open(url, '_blank')
    } catch (e) {
      // console.log('🚀 ~ getBenchmarks ~ e:', e)
    }
  }

  useEffect(() => {
    const leadTest = JSON.parse(localStorage.getItem('lead_form'))
    const loginThroughWebLinkLocalstorage = JSON.parse(localStorage.getItem('loginThroughWebLink'))
    if (loginThroughWebLinkLocalstorage) {
      if (leadTest) {
        history.push('/dashboard')
      }
      const selectaccountFromLocalstorage = JSON.parse(localStorage.getItem('selectAccount'))
      if (selectaccountFromLocalstorage) {
        if (selectaccountFromLocalstorage) {
          const customerFromLocalStorage = JSON.parse(localStorage.getItem('customer'))
          if (customerFromLocalStorage) {
            const generatingFromLocalstorage = JSON.parse(localStorage.getItem('report-generating'))
            if (!generatingFromLocalstorage && !leadTest) {
              history.push(`/report-generating/${selectaccountFromLocalstorage}`)
            }
          }
        }
      } else {
        // eslint-disable-next-line
        if (!JSON.parse(localStorage.getItem('errorCustomer')) && !leadTest) {
          history.push('/select-account')
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isCalculatorOpen, isBenchmarkOpen, isAdsPreviewOpen])

  useEffect(() => {
    if (isGenerateReport === true) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isGenerateReport])
  useEffect(() => {
    if (!selectedTab && !isLoading && !loadError) {
      dispatch(updateCurrentReport({ currentReport: true }))
    } else {
      dispatch(updateCurrentReport({ currentReport: false }))
    }
    // eslint-disable-next-line
  }, [selectedTab, isLoading, loadError])
  useEffect(() => {
    fetchQuestionnaireQuestions()
      .then(res => {
        const data = res?.data?.should_show_questionnaire
        if (JSON.parse(localStorage.getItem('showPopover')) === null) {
          localStorage.setItem('showPopover', data ?? false)
        }
        setQuestionnaireData(
          // eslint-disable-next-line
          res?.data?.questions?.filter(item => {
            // eslint-disable-next-line
            return item?.is_answered === false
          })
        )
        setTrueAnswered(
          // eslint-disable-next-line
          res?.data?.questions?.filter(item => {
            // eslint-disable-next-line
            return item?.is_answered === true
          })
        )
        setAllQuestionnaireData(res?.data?.questions)
      })
      .catch(error => {
        // console.log(error)
      })
  }, [])
  const pannelItem = [
    'Alerts Overview',
    // 'Alerts Setup / Edits',
    // 'Alerts Formula',
    // 'Manage Email Alerts',
  ]
  const capmaignPanelItem = [
    'Creative Library',
    // 'Alerts Setup / Edits',
    // 'Alerts Formula',
    // 'Manage Email Alerts',
  ]
  const handleButtonAlert = index => {
    // Handle button click based on the index or identifier
    setIsBenchmarkOpen(false)
    setIsCalculatorOpen(false)
    setIsAlert(true)
    setIpannelItemClick(index)
  }
  const handleButtonPreview = index => {
    // Handle button click based on the index or identifier
    setIsAdsPreviewOpen(true)
    setIsBenchmarkOpen(false)
    setIsCalculatorOpen(false)
    setIsAlert(false)
    setIpannelItemClick(index)
  }

  return (
    <div className={styles.container}>
      {/* {!sampleReport && ( */}
      <div className={styles.sidebar}>
        <div className={styles.sidebarTop}>
          {/* <CustomersSelect
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            handleSelectUser={handleSelectUser}
            rightMenu
            setCurrentSection={setCurrentSection}
            setReports={setReports}
          /> */}
          {/* <button
            className={`${styles.sidebarButton} ${selectedTab === 'dashboard' && styles.active}`}
            type="button"
            onClick={() => setSelectedTab('dashboard')}
          >
            Dashboard
          </button> */}
          <div id="reportCollapse" className={styles.collapse} style={{ background: '#E6E9EC' }}>
            <div
              style={{
                color: '#939395',
                fontSize: 10,
                background: '#E6E9EC',
                paddingLeft: 31,
                paddingTop: 6,
                paddingBottom: 6,
              }}
            >
              TRACK
            </div>
            <Collapse
              accordion
              expandIconPosition="end"
              bordered={false}
              style={{
                background:
                  !isCalculatorOpen &&
                  !isBenchmarkOpen &&
                  !isAdsPreviewOpen &&
                  !isLearningCenterOpen
                    ? '#fff'
                    : '#F1F3F4',
                marginTop: -10,
                border: 'none',
              }}
              activeKey={open}
              onChange={() => setOpen(prevOpen => (prevOpen.includes('1') ? [] : ['1']))}
            >
              {/* <div
                style={{
                  display: 'flex',
                  borderTop: '1px solid #d9d9d9',
                  gap: 8,
                }}
              > */}
              {/* <img
                src={learningIcon}
                style={{ marginTop: 19, marginLeft: 30 }}
                height={19}
                alt="Consulting Services logo"
              />
              <Panel
                header={
                  <div
                    className={`${styles.reportHeader} ${
                      reports?.includes(selectedTab) && styles.active
                    } `}
                  >
                    {t('reports')}
                  </div>
                }
                onChange={() => setOpen(prev => [1])}
                key="1"
              >
                <div
                  className={`${styles.reportsAccordion} customScrollbar`}
                  style={{
                    height: '50%',
                    overflow: 'auto',
                    paddingBottom: '20px',
                  }}
                >
                  {sections.map(section => (
                    <button
                      type="button"
                      key={section.key}
                      className={`${styles.sectionName} ${
                        currentSection === section.key &&
                        !isCalculatorOpen &&
                        !isBenchmarkOpen &&
                        !isAdsPreviewOpen &&
                        !isLearningCenterOpen &&
                        styles.active
                      }`}
                      onClick={() => handleSectionClick(section.key)}
                      // disabled={isLoading || !reports?.length}
                      disabled={isLoading}
                    >
                      {section.label}
                    </button>
                  ))}
                </div>
              </Panel> */}
              {/* </div> */}
              <Panel
                id="dashboard-collapse"
                header={
                  <div style={{ display: 'flex', paddingTop: 4, gap: 8, border: 'none' }}>
                    <img
                      src={reportsIcon}
                      style={{ marginLeft: 8, marginTop: -5, border: 'none' }} // Adjust margin as needed
                      height={32}
                      alt="Reports logo"
                    />
                    <div
                      className={`${styles.reportHeader} ${
                        reports?.includes(selectedTab) && styles.active
                      } `}
                      style={{ border: 'none' }}
                    >
                      {t('reports')}
                    </div>
                  </div>
                }
                onChange={() => setOpen(prev => [1])}
                key="1"
              >
                <div
                  className={`${styles.reportsAccordion} customScrollbar`}
                  style={{
                    marginTop: -7,
                    height: '50%',
                    overflow: 'auto',
                    paddingBottom: '20px',
                    paddingLeft: 59,
                  }}
                >
                  {sections.map(section => (
                    <button
                      type="button"
                      key={section.key}
                      className={`${styles.sectionNameCollapse} ${
                        currentSection === section.key &&
                        !isCalculatorOpen &&
                        !isBenchmarkOpen &&
                        !isAdsPreviewOpen &&
                        !isLearningCenterOpen &&
                        styles.active
                      }`}
                      onClick={() => handleSectionClick(section.key)}
                      disabled={isLoading}
                    >
                      {section.label}
                    </button>
                  ))}
                </div>
              </Panel>
              <div
                style={{
                  color: '#939395',
                  fontSize: 10,
                  background: '#E6E9EC',
                  paddingLeft: 31,
                  paddingTop: 6,
                  paddingBottom: 6,
                }}
              >
                PLAN
              </div>
              <div
                style={
                  {
                    // padding: '0 0 4px 0',
                  }
                }
              >
                <div
                  style={{
                    display: 'flex',
                    background: isCalculatorOpen ? '#fff' : '#F1F3F4',
                    borderTop: '1px solid #d9d9d9',
                    gap: 8,
                  }}
                >
                  <img
                    src={calculatorIcon}
                    style={{ marginTop: 13, marginLeft: 24 }}
                    height={32}
                    alt="Calculator logo"
                  />
                  <button
                    type="button"
                    className={styles.sectionName}
                    style={{
                      background: isCalculatorOpen ? '#fff' : '#F1F3F4',
                      color: '#26282C',
                      fontWeight: isCalculatorOpen && 'bold',
                      padding: '1rem 0px 1rem 0rem',
                    }}
                    // style={{
                    //   paddingLeft: '6px',
                    //   color: '#000000D9',
                    //   fontWeight: '700',
                    //   border: 'none',
                    //   backgroundColor: 'transparent',
                    //   cursor: 'pointer',
                    // }}
                    // to="/calculator"
                    onClick={e => handleCalculator(e)}
                  >
                    {/* eslint-disable-next-line */}
                    {t('report.tabs.adsCalculator')}
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    background: isBenchmarkOpen ? '#fff' : '#F1F3F4',
                    borderTop: '1px solid #d9d9d9',
                    gap: 8,
                  }}
                >
                  <img
                    src={benchmarksIcon}
                    style={{ marginTop: 10, marginLeft: 24 }}
                    height={32}
                    alt="Benchmarks logo"
                  />
                  <button
                    type="button"
                    className={styles.sectionName}
                    style={{
                      background: isBenchmarkOpen ? '#fff' : '#F1F3F4',
                      color: '#26282C',
                      fontWeight: isBenchmarkOpen && 'bold',
                      padding: '1rem 0px 1rem 0rem',
                      width: '100%',
                    }}
                    onClick={e => handleBenchMark(e)}
                  >
                    {/* eslint-disable-next-line */}
                    {t('benchmark.buttonText')}
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    background: isAdsPreviewOpen ? '#fff' : '#F1F3F4',
                    borderTop: '1px solid #d9d9d9',
                    gap: 13,
                  }}
                >
                  <img
                    src={creativeIcon}
                    style={{ marginTop: 14, marginLeft: 30 }}
                    height={23}
                    alt="Creative library logo"
                  />
                  <button
                    type="button"
                    className={styles.sectionName}
                    style={{
                      background: isAdsPreviewOpen ? '#fff' : '#F1F3F4',
                      color: '#26282C',
                      fontWeight: isAdsPreviewOpen && 'bold',
                      padding: '1rem 0px 1rem 0px',
                      width: '100%',
                    }}
                    onClick={e => handleAdsPreview(e)}
                  >
                    Creative Library
                  </button>
                </div>
                <div
                  style={{
                    color: '#939395',
                    fontSize: 10,
                    background: '#E6E9EC',
                    paddingLeft: 31,
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                >
                  LEARN
                </div>
                <div
                  style={{
                    display: 'flex',
                    background: isLearningCenterOpen ? '#fff' : '#F1F3F4',
                    borderTop: '1px solid #d9d9d9',
                    gap: 17,
                  }}
                >
                  <img
                    src={learningIcon}
                    style={{ marginTop: 19, marginLeft: 30 }}
                    height={19}
                    alt="Learning center logo"
                  />
                  <button
                    type="button"
                    className={styles.sectionName}
                    style={{
                      background: isLearningCenterOpen ? '#fff' : '#F1F3F4',
                      color: '#202020',
                      fontWeight: isLearningCenterOpen && 'bold',
                      padding: '1rem 0px 1rem 0rem',
                      width: '100%',
                    }}
                    onClick={e => handleLearningCenter(e)}
                  >
                    {/* eslint-disable-next-line */}
                    {t('report.tabs.learningCenter')}
                  </button>
                </div>
              </div>
            </Collapse>
            {/* Alerts collapse  */}
            {/* <SideDrawerCollapse
                panelHeader="Under/Over Spend Alerts"
                pannelItem={pannelItem}
                handlePanelClick={handleButtonAlert}
                icon={AlertIcon}
              /> */}
            {/* <Collapse
                accordion
                expandIconPosition="end"
                bordered={false}
                defaultActiveKey={['1']}
              >
                <Panel header={<div>Under/Over Spend Alerts</div>} key="1">
                  <div
                    className={`${styles.reportsAccordion} customScrollbar`}
                    style={{
                      height: '50%',
                      overflow: 'auto',
                      paddingBottom: '20px',
                    }}
                  >
                    <button
                      type="button"
                      className={`${styles.sectionName} ${styles.active}`}
                      // onClick={() => handlePanelClick()}
                      disabled={isLoading || !reports?.length}
                    >
                      sectionLable
                    </button>
                  </div>
                </Panel>
              </Collapse> */}
          </div>
          <div
            style={{
              color: '#939395',
              fontSize: 10,
              background: '#E6E9EC',
              paddingLeft: 31,
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            HIRE & HELP
          </div>
          <div className={styles.consulting2}>
            <span
              className={styles.textWithLogo}
              style={{ paddingLeft: 22 }}
              // href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting"
              // target="_blank"
              // rel="noreferrer"
            >
              <img src={consultingIcon} alt="Consulting Services logo" />
              <button
                className={styles.belowReportButton}
                type="button"
                onClick={handleOpenConsulting}
              >
                {t('consultingServicesButtonText')}
              </button>
            </span>
          </div>
          <div className={styles.belowReportsAccordion} style={{ display: 'none' }}>
            <span
              className={styles.textWithLogo}
              // href="https://neilpatel.com/consulting/?utm_medium=ref&utm_source=ads-grader&utm_campaign=us-mkt-campaigns-mql-tool-ads-grader&utm_content=button-left-bottom-consulting&utm_term=tool-consulting"
              // target="_blank"
              // rel="noreferrer"
            >
              <img src={consultingIcon} alt="Consulting Services logo" />
              <button
                className={styles.belowReportButton}
                type="button"
                onClick={handleOpenConsulting}
              >
                {t('consultingServicesButtonText')}
              </button>
            </span>
          </div>
        </div>
      </div>
      {/*  )} */}
      <div id="scrollableDiv" className={`${styles.reportContainer} customScrollbar`}>
        <div id="report">
          {/* eslint-disable-next-line */}
          {selectedTab === 'Dashboard' ? (
            <div>{t('dashboardText')}</div>
          ) : // eslint-disable-next-line
          isCalculatorOpen ? (
            <div ref={containerRef}>
              <Calculator />
            </div>
          ) : // eslint-disable-next-line
          isBenchmarkOpen ? (
            <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
              <Benchmark />
            </div>
          ) : // eslint-disable-next-line
          isLearningCenterOpen ? (
            <div
              ref={containerRef}
              style={{ padding: '10px 10px 3.7rem 10px', background: '#F9FAFB' }}
            >
              <LearningCenter />
            </div>
          ) : // eslint-disable-next-line
          isAlert ? (
            <div ref={containerRef} style={{ padding: '10px 10px 3.7rem 10px' }}>
              <AgAlertsPage pannelItemClicked={pannelItemClick} />
            </div>
          ) : // eslint-disable-next-line
          isAdsPreviewOpen ? (
            <div
              ref={containerRef}
              style={{
                padding: '10px 10px 3.7rem 10px',
                background: 'var(--secondary-tints-gainsboro-20, #F9FAFB)',
              }}
            >
              <AdsPreview pannelItemClicked={pannelItemClick} />
            </div>
          ) : (
            <div ref={containerRef}>
              {JSON.parse(localStorage.getItem('showPopover')) &&
                window.location.pathname === '/dashboard' &&
                questionnaireData.length > 0 &&
                JSON.parse(localStorage.getItem('isCustomers')) && (
                  <Questionnaire
                    data={questionnaireData}
                    wholeData={allQuestionnaireData}
                    trueOnly={trueAnswered}
                  />
                )}
              {!isLoading && (
                <>
                  {/* {JSON.parse(localStorage.getItem('showPopover')) &&
                  window.location.pathname === '/dashboard' &&
                  questionnaireData.length > 0 && (
                    <Questionnaire data={questionnaireData} wholeData={allQuestionnaireData} />
                  )} */}
                  <ReportHeader
                    reports={reports}
                    setCurrentReport={setCurrentReport}
                    currentReport={currentReport}
                  />
                </>
              )}
              {selectedTab && !isLoading && (
                <>
                  <ReportPageTemporary
                    selectedReportData={currentReport}
                    setCurrentSection={setCurrentSection}
                  />
                </>
              )}
              {!selectedTab && !isLoading && loadError && (
                <div className={styles.selectReport}>
                  <h1 className={styles.selectReportHeading}>{t('loadReportErrorText')}</h1>
                  {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                </div>
              )}
              {!selectedTab && !isLoading && !loadError && !sampleReport && !tempTest && (
                <div className={styles.selectReport}>
                  <h1 className={styles.selectReportHeading}>{t('noReportText')}</h1>
                  {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                </div>
              )}
              {sampleReport && (
                <div className={`${styles.selectReport} ${styles.selectReport_sample}`}>
                  <SampleReport />
                  {/* <h1 className={styles.selectReportHeading}>{t('noReportText')}</h1> */}
                  {/* <img src={reportImg} alt="report" width={700} height={500} /> */}
                </div>
              )}
              {isLoading && !sampleReport && (
                <div className={styles.loadingContainer}>
                  <Spin />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
