import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { Tabs, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { AG_AC_TOKEN, LS_KEY, ACCOUNT_TAB, MANAGE_USERS_TAB } from '@utils/constants'
import { notify } from '@utils/notify'
// import { tabs } from '../../data'
import { deactivateAccount } from '@services/deactivateAccount'
import { logoutUser, updateUserData } from '../../store/actions'
import api from '../../services/api'
import TabContent from './tabContent'

const SettingTabs = () => {
  const [currentTab, setCurrentTab] = useState(ACCOUNT_TAB)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const tabs = [
    {
      label: `${t('settings.account.account')}`,
      key: ACCOUNT_TAB,
    },
    {
      label: `${t('settings.account.manageUsers')}`,
      key: MANAGE_USERS_TAB,
    },
    // {
    //   label: 'Platform Integrations',
    //   key: PLATFORM_INTEGRATIONS,
    // },
  ]

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      campaign_performance_alert: 'Weekly',
      email: '',
      profile: {
        currency: 'USD',
        report_format: 'pdf',
      },
    },
    onSubmit: async values => {
      setIsLoading(true)
      try {
        const res = await api({
          url: '/accounts/user/',
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AG_AC_TOKEN)}`,
          },
          data: { ...values },
        })
        setIsLoading(false)
        if (res.status === 200) {
          localStorage.setItem(LS_KEY, JSON.stringify({ ...user, user: { ...res.data } }))
          dispatch(updateUserData({ ...user, user: { ...res.data } }))
          notify(t('notification.userUpdated'), 'success')
        }
      } catch (e) {
        setIsLoading(false)
        notify(t('notification.somethingWentWrong'), 'error')
        // console.log('error user update~:', e)
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      first_name: user?.user?.first_name,
      last_name: user?.user?.last_name,
      // campaign_performance_alert: 'Weekly',
      email: user?.user?.email,
      profile: {
        currency: user?.user?.profile?.currency || 'USD',
        report_format: user?.user?.profile?.report_format || 'pdf',
      },
    })
    // eslint-disable-next-line
  }, [user])

  const handleCancel = () => {
    formik.resetForm({
      values: {
        first_name: user?.user?.first_name,
        last_name: user?.user?.last_name,
        email: user?.user?.email,
        campaign_performance_alert: 'Weekly',
        profile: {
          currency: user?.user?.profile?.currency || 'USD',
          report_format: user?.user?.profile?.report_format || 'pdf',
          // campaign_performance_alert: user?.user?.profile?.campaign_performance_alert,
        },
      },
    })
  }

  const DeactivateAccount = async () => {
    try {
      const res = await deactivateAccount()
      if (res.status === 204) {
        notify('Account has been deleted!', 'success')
        setDeleteLoading(true)
        dispatch(logoutUser())
        // eslint-disable-next-line
        history.push('/')
        localStorage.removeItem('customer')
        localStorage.removeItem('report-generating')
        localStorage.removeItem('selectAccount')
        localStorage.removeItem('loginThroughWebLink')
        localStorage.removeItem('url')
        localStorage.removeItem('leadForm')
        localStorage.removeItem('showForm')
        localStorage.removeItem('term')
        localStorage.removeItem('content')
        localStorage.removeItem('source')
        localStorage.removeItem('?medium')
        localStorage.removeItem('campaign')
        localStorage.removeItem('_gl')
        localStorage.removeItem('ads_grader_saved_utms')
        localStorage.removeItem('isSearchFeature')
        localStorage.removeItem('profilePic')
      } else {
        notify(t('notification.somethingWentWrong'), 'error')
        // console.log('error while deactivating user account!', res)
      }
    } catch (e) {
      setDeleteLoading(true)
      // console.log('deactivate user account error~: ', e.response)
    }
  }

  const closeModal = () => {}

  const handleDeleteButton = () => {
    Modal.confirm({
      title: t('deleteAccountHeading'),
      icon: <ExclamationCircleOutlined />,
      // content: 'Are you sure, You want to remove the selected user?',
      content: t('deleteAccount'),
      okText: t('delete'),
      cancelText: t('cancel'),
      okButtonProps: { size: 'small' },
      cancelButtonProps: { size: 'small' },
      // confirmLoading: deleteLoading,
      onOk: () => DeactivateAccount(),
      onCancel: closeModal,
    })
  }

  return (
    <Tabs
      activeKey={currentTab}
      type="card"
      onChange={key => setCurrentTab(key)}
      items={tabs.map((tab, index) => ({
        label: tab.label,
        key: tab.key,
        children: (() => (
          <TabContent
            tab={tab}
            index={index}
            formik={formik}
            userUpdateLoading={isLoading}
            handleCancel={handleCancel}
            DeactivateAccount={handleDeleteButton}
          />
        ))(),
      }))}
    />
  )
}
export default SettingTabs
