import {
  UPDATE_USER,
  LOGOUT_USER,
  UPDATE_CUSTOMER,
  UPDATE_REPORT,
  UPDATE_ROLE,
  UPDATE_ERROR_CUSTOMER,
  UPDATE_REPORT_DASHBOARD,
  UPDATE_GENERATE_REPORT,
  UPDATE_CURRENT_REPORT,
  UPDATE_SAMPLE_REPORT,
  LEARNING_CENTER,
  WEEKLY_REPORT,
  SINGLE_REPORT,
  REPORT_INDEX,
  ALL_REPORTS,
  PAGINATED_REPORTS,
  PAGINATED_NEXT,
  REPORT_CURRENT_ID,
} from '@utils/constants'
import { clearLocalStorage } from '@utils/helperFunctions'

export const updateUserData = user => ({ type: UPDATE_USER, payload: user })

export const updateSelectedCustomer = customer => ({ type: UPDATE_CUSTOMER, payload: customer })
export const updateSampleReport = sampleReport => ({
  type: UPDATE_SAMPLE_REPORT,
  payload: sampleReport,
})

export const updateReport = report => ({ type: UPDATE_REPORT, payload: report })
export const updateCurrentReport = currentReport => ({
  type: UPDATE_CURRENT_REPORT,
  payload: currentReport,
})
export const updateGenerateReport = report => ({ type: UPDATE_GENERATE_REPORT, payload: report })
export const updateRole = role => ({ type: UPDATE_ROLE, payload: role })
export const addFilteredChartReport = filteredReport => ({
  type: WEEKLY_REPORT,
  payload: filteredReport,
})
export const updateReportDashboard = reportDashboard => ({
  type: UPDATE_REPORT_DASHBOARD,
  payload: reportDashboard,
})
export const updateErrorCustomer = errorCustomer => ({
  type: UPDATE_ERROR_CUSTOMER,
  payload: errorCustomer,
})

export const logoutUser = () => {
  clearLocalStorage()
  return { type: LOGOUT_USER }
}
export const getLearningCenterData = () => ({
  type: LEARNING_CENTER,
})
export const singleReport = singleRep => ({
  type: SINGLE_REPORT,
  payload: singleRep,
})
export const reportIndex = index => ({
  type: REPORT_INDEX,
  payload: index,
})
export const allReports = rep => ({
  type: ALL_REPORTS,
  payload: rep,
})
export const paginatedReports = pagniate => ({
  type: PAGINATED_REPORTS,
  payload: pagniate,
})
export const paginatedNext = next => ({
  type: PAGINATED_NEXT,
  payload: next,
})
export const reportCurrentId = id => ({
  type: REPORT_CURRENT_ID,
  payload: id,
})
