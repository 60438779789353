import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { passwordRules } from '@utils/validation'
import { notify } from '@utils/notify'
import { baseURL } from '@services/requests'
import { updateLocalStorage } from '@utils/helperFunctions'
import { fetchGoogleUserInfo } from '@services/fetchGoogleUserInfo'
import { fetchCustomersList } from '@services/fetchCustomersList'
import { fetchInvitersList } from '@services/fetchInvitersList'
import { updateUserData } from '../../../store/actions'
import { apiRoutes } from '../../../config/apiRoutes'
import api from '../../../services/api'
import Authentication from '../../../components/Authentication'

import styles from './login-route.module.css'

const validationSchema = yup.object().shape({
  email: yup.string().email('Enter valid email.').required('* Required'),
  password: yup.string().required().min(8).label('Password'),
})

const LoginRoute = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [googleUserLoading, setGoogleUserLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async values => {
      setIsLoading(true)
      try {
        const res = await api({
          url: apiRoutes.login.url,
          method: apiRoutes.login.method,
          data: values,
        })
        setIsLoading(false)
        if (res.status === 200) {
          const userData = { ...res.data }
          userData.isGoogleUser = false
          updateLocalStorage(userData)
          dispatch(updateUserData(userData))
          history.push('/dashboard')
          notify(t('notification.loggedInSuccessfully'), 'success')
        }
      } catch (e) {
        setIsLoading(false)
        if (e?.response?.data?.non_field_errors?.length) {
          notify(t('notification.invalidCredentials'), 'error')
        } else if (e?.response?.status === 500) {
          notify(t('notification.internalServer'), 'error')
        } else {
          notify(t('notification.somethingWentWrong'), 'error')
        }
      }
    },
  })
  const getUniqueIDFromURL = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const reportId = url.searchParams.get('reportId')
    const customerId = url.searchParams.get('customerId')
    return { reportId, customerId }
  }
  useEffect(() => {
    const { reportId, customerId } = getUniqueIDFromURL()
    if ((reportId, customerId)) {
      localStorage.setItem('copied_report_unique_id', reportId)
      localStorage.setItem('copied_report_customer_id', customerId)
    }
    if (formik?.values?.email) {
      formik.setFieldTouched('email')
    }
    if (formik?.values?.password) {
      formik.setFieldTouched('password')
    }
    // eslint-disable-next-line
  }, [formik.values?.email, formik.values?.password])

  const handleGoogleLoginSuccess = async payload => {
    setGoogleUserLoading(true)
    fetchGoogleUserInfo(payload.code)
      .then(res => {
        // setGoogleUserLoading(false)
        const userData = { ...res.data }
        userData.isGoogleUser = true
        if (res.status === 200) {
          localStorage.setItem('profilePic', res?.data?.user?.profile?.profile_picture)
          notify(t('notification.loginSuccess'), 'success')
          updateLocalStorage(userData)
          dispatch(updateUserData(userData))
          if (
            localStorage.getItem('copied_report_unique_id') &&
            localStorage.getItem('copied_report_customer_id')
          ) {
            history.push('/report-dashboard')
          } else {
            // history.push('/dashboard')
            // localStorage.setItem('leadForm', JSON.stringify(userData?.user?.is_lead_save))
            // console.log(
            //   'JSON.parse(localStorage.getItem()',
            //   JSON.parse(localStorage.getItem('leadForm'))
            // )
            // eslint-disable-next-line
            // .................................................................................................................................

            localStorage.setItem('leadForm', JSON.stringify(userData?.user?.is_lead_save))
            // history.push('/lead-form')
            // inviter_customers
            if (userData?.user?.is_lead_save) {
              history.push('/dashboard')
            } else {
              // fetchCustomersList()
              //   .then(response => {
              //     console.log('🚀 ~ handleGoogleLoginSuccess ~ response:', response)
              //     if (response.status === 200 && response.data?.length) {
              //       console.log('customer response.data oooo', response.data)
              //     }
              //   })
              //   .catch(e => {
              //     console.log('customers_api error~: oooo', e.response)
              //   })
              // fetchInvitersList()
              //   .then(inviterRes => {
              //     // if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
              //     if (!inviterRes.data.inviter_customers.length) {
              //       fetchCustomersList()
              //         .then(response => {
              //           history.push('/lead-form')
              //           setGoogleUserLoading(false)
              //         })
              //         .catch(e => {
              //           history.push('/lead-form')
              //           setGoogleUserLoading(false)
              //           console.log('customers_api error~: oooo', e.response)
              //         })
              //     } else {
              fetchCustomersList()
                .then(response => {
                  if (response?.data?.length) {
                    history.push('/lead-form')
                    setGoogleUserLoading(false)
                  } else if (response.status.includes('UNAUTHENTICATED')) {
                    fetchInvitersList().then(inviterRes => {
                      if (
                        inviterRes?.status === 200 &&
                        inviterRes.data?.inviter_customers?.length
                      ) {
                        history.push('/dashboard')
                        setGoogleUserLoading(false)
                      } else {
                        history.push('/select-sample-account')
                        setGoogleUserLoading(false)
                      }
                    })
                  } else {
                    history.push('/dashboard')
                    setGoogleUserLoading(false)
                  }
                })
                .catch(e => {
                  fetchInvitersList().then(inviterRes => {
                    if (inviterRes?.status === 200 && inviterRes.data?.inviter_customers?.length) {
                      history.push('/dashboard')
                      setGoogleUserLoading(false)
                    } else {
                      history.push('/select-sample-account')
                      setGoogleUserLoading(false)
                    }
                  })
                })
              //   }
              // })
              // .catch(e => {
              //   setGoogleUserLoading(false)
              // })
            }
            // .................................................................................................................................
          }
        } else {
          // eslint-disable-next-line
          if (res?.status >= 500) {
            notify(t('notification.noRecordError'), 'success')
            // notify(t('notification.internalServer'), 'error')
          } else {
            notify(t('notification.somethingWentWrong'), 'error')
          }
        }
      })
      .catch(e => {
        setGoogleUserLoading(false)
      })
  }

  return (
    <div className={styles.loginContainer}>
      {googleUserLoading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        <Authentication
          componentType="login"
          formik={formik}
          isLoading={isLoading}
          handleGoogleLogin={handleGoogleLoginSuccess}
          title="Login to Ads Grader"
          buttonText="Login"
        />
      )}
    </div>
  )
}

export default LoginRoute
