import React, { useEffect, useState } from 'react'
import GoogleLogin from 'react-google-login'
import { useTranslation } from 'react-i18next'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Checkbox, Divider, Spin } from 'antd'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Error from '@components/error'
import { notify } from '@utils/notify'
import Tooltip from '../tooltip'
import InputField from '../Input'
import styles from './authentication.module.css'

const googleClientId = process.env.REACT_APP_GOOGLE_PROJECT_CLIENT_ID

const showField = (formik, key) => Object.keys(formik.values).includes(key)

const Authentication = ({
  componentType,
  formik,
  isLoading,
  handleGoogleLogin,
  title,
  description,
  buttonText,
}) => {
  const { getFieldProps } = formik
  const [disableGoogleButton, setDisableGoogleButton] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const onFailure = e => {
    // if (e.error !== 'popup_closed_by_user') {
    //   setDisableGoogleButton(true)
    //   notify(e.details, 'error')
    // }
  }
  useEffect(() => {
    const handleEvent = event => {
      if (event.key === 'Enter') {
        formik.handleSubmit()
      }
    }

    document.addEventListener('keydown', handleEvent)

    return () => {
      document.removeEventListener('keydown', handleEvent)
    }
    // eslint-disable-next-line
  }, [])
  const getUniqueIDFromURL = () => {
    const currentUrl = window.location.href
    const url = new URL(currentUrl)
    const reportId = url.searchParams.get('reportId')
    const customerId = url.searchParams.get('customerId')
    return { reportId, customerId }
  }

  useEffect(() => {
    const user = localStorage.getItem('np_ads_grader:session')
    const userObject = JSON.parse(user)
    const userEmail = userObject?.user.email
    const accessToken = localStorage.getItem('adsGraderAccessToken')
    const inviteForm = localStorage.getItem('inviteFrom')
    const jsonObject = JSON.parse(inviteForm)
    const inviteFormEmail = jsonObject?.email
    if (accessToken) {
      if (userEmail === inviteFormEmail) {
        const { reportId, customerId } = getUniqueIDFromURL()
        if ((reportId, customerId)) {
          localStorage.setItem('copied_report_unique_id', reportId)
          localStorage.setItem('copied_report_customer_id', customerId)
        }
        // history.push('/report-dashboard')
        history.push('/dashboard')
      } else if (
        userEmail?.includes('npaccel.com') ||
        userEmail?.includes('npdigital.com') ||
        userEmail?.includes('neilpatel.com') ||
        userEmail?.includes('ubersuggest.com') ||
        userEmail?.includes('grayphite.com')
      ) {
        const { reportId, customerId } = getUniqueIDFromURL()
        if ((reportId, customerId)) {
          localStorage.setItem('copied_report_unique_id', reportId)
          localStorage.setItem('copied_report_customer_id', customerId)
        }

        history.push('/report-dashboard')
      }
    } else {
      //  eslint-disable-next-line
      if (
        userEmail?.includes('npaccel.com') ||
        userEmail?.includes('npdigital.com') ||
        userEmail?.includes('neilpatel.com') ||
        userEmail?.includes('ubersuggest.com') ||
        userEmail?.includes('grayphite.com')
      ) {
        const { reportId, customerId } = getUniqueIDFromURL()
        if ((reportId, customerId)) {
          localStorage.setItem('copied_report_unique_id', reportId)
          localStorage.setItem('copied_report_customer_id', customerId)
          history.push('/report-dashboard')
        }
      }
      // history.push('/report-dashboard')
      // User is not logged in, show login options
    }

    // eslint-disable-next-line
  }, [history])
  return (
    <div className={styles.loginRouteWrapper}>
      {/* <h2 className={styles.bold}>Ads Grader</h2>
<p className={styles.bold}>
by <span className={styles.NP}>NP</span> digital
</p> */}
      {/* {title && <h3>{title}</h3>} */}
      {/* {componentType === 'register' && (
<h3>
Register to Ads Grader for{' '}
<span className={`${styles.forgotPassword} ${styles.bold} ${styles.cursorDefault}`}>
free!
</span>
</h3>
)} */}
      {description && <p>{description}</p>}
      <div className={styles.loginFormCard}>
        <form className={styles.loginForm} onSubmit={formik.handleSubmit}>
          {/* {showField(formik, 'first_name') && (
<div className={styles.inputWrapper}>
<InputField
label="First Name"
inputProps={{
name: 'first_name',
autoComplete: 'false',
placeholder: 'Enter first name',
...getFieldProps('first_name'),
}}
endAdornment={
!!formik.touched.first_name && !!formik.errors.first_name ? (
<Error text={formik.errors.first_name} />
) : (
<CheckCircleOutlined />
)
}
isError={Boolean(formik.touched.first_name) && Boolean(formik.errors.first_name)}
isSuccess={!!formik.touched.first_name && !formik.errors.first_name}
/>
</div>
)} */}
          {/* {showField(formik, 'last_name') && (
<div className={styles.inputWrapper}>
<InputField
label="Last Name"
inputProps={{
name: 'last_name',
autoComplete: 'false',
placeholder: 'Enter last name',
...getFieldProps('last_name'),
}}
endAdornment={
!!formik.touched.last_name && !!formik.errors.last_name ? (
<Error text={formik.errors.last_name} />
) : (
<CheckCircleOutlined />
)
}
isError={Boolean(formik.touched.last_name) && Boolean(formik.errors.last_name)}
isSuccess={!!formik.touched.last_name && !formik.errors.last_name}
/>
</div>
)} */}
          {/* {showField(formik, 'email') && (
<div className={styles.inputWrapper}>
<InputField
label="Email"
inputProps={{
placeholder: 'Enter Email',
name: 'email',
autoComplete: 'false',
...getFieldProps('email'),
}}
endAdornment={
!!formik.touched.email && !!formik.errors.email ? (
<Error text={formik.errors.email} />
) : (
<CheckCircleOutlined />
)
}
isError={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
isSuccess={!!formik.touched.email && !formik.errors.email}
/>
</div>
)} */}
          {/* {showField(formik, 'password') && (
<div className={styles.inputWrapper}>
<InputField
label="Password"
inputProps={{
type: 'password',
placeholder: 'Enter password',
name: 'password',
autoComplete: 'false',
...getFieldProps('password'),
}}
endAdornment={
!!formik.touched.password && !!formik.errors.password ? (
<Error text={formik.errors.password} />
) : (
<CheckCircleOutlined />
)
}
isError={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
isSuccess={!!formik.touched.password && !formik.errors.password}
/>
</div>
)} */}
          {/* {componentType === 'login' && (
<div className={styles.fogotPasswordWrapper}>
<p className={styles.forgotPassword}>
<Link to="/forgot-password">Forgot Password</Link>
</p>
</div>
)} */}
          {/* {componentType === 'register' && (
<div>
<div className={styles.checkboxContainer}>
<Checkbox
name="terms_and_conditions"
className={styles.checkboxText}
checked={formik.values.terms_and_conditions}
{...getFieldProps('terms_and_conditions')}
>
I agree to the{' '}
<span className={`${styles.forgotPassword} ${styles.checkboxText}`}>
<Link to="/privacy" target="_blank" rel="noopener noreferrer">
Privacy Policy
</Link>
</span>{' '}
&{' '}
<span className={`${styles.forgotPassword} ${styles.checkboxText}`}>
<Link to="/terms" target="_blank" rel="noopener noreferrer">
Terms of Service
</Link>
</span>{' '}
<span className={`${styles.error} `}>(required)</span>
</Checkbox>
</div>
{formik.touched.terms_and_conditions &&
Boolean(formik.errors.terms_and_conditions) && (
<p className={styles.error}>{formik.errors.terms_and_conditions}</p>
)}
<div>
<Checkbox
name="receive_emails"
className={styles.checkboxText}
checked={formik.values.receive_emails}
{...getFieldProps('receive_emails')}
>
I agree to receive emails that wil teach me how to generate more traffic and use
Ubersuggest <span className={styles.error}>(required)</span>
</Checkbox>
</div>
{formik.touched.receive_emails && Boolean(formik.errors.receive_emails) && (
<p className={styles.error}>{formik.errors.receive_emails}</p>
)}
</div>
)} */}
          {/* {isLoading && <Spin />}
{!isLoading && (
<button
className={styles.submitButton}
type="submit"
disabled={
!Object.keys(formik?.touched)?.length || !!Object.keys(formik?.errors)?.length
}
>
{buttonText}
</button>
)} */}
          {componentType !== 'forgotPassword' && (
            <>
              {/* <Divider className={styles.seperator}>or</Divider> */}
              <div className={styles.googleButtonContainer}>
                <GoogleLogin
                  clientId={googleClientId}
                  render={props => (
                    <button
                      type="button"
                      onClick={props.onClick}
                      // disabled={props.disabled || disableGoogleButton}
                      className={styles.googleButton}
                      href="#continue-with-google"
                    >
                      <span className={styles.googleImageContianer}>
                        <img
                          src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png"
                          width={20}
                          height={20}
                          alt="google"
                        />
                      </span>
                      {t('continueWithGoogle')}
                    </button>
                  )}
                  buttonText="Login with Google"
                  onSuccess={handleGoogleLogin}
                  onFailure={onFailure}
                  scope="profile email https://www.googleapis.com/auth/adwords"
                  accessType="offline"
                  responseType="code"
                  prompt="select_account"
                />
              </div>
            </>
          )}
        </form>
      </div>
      {/* {componentType === 'login' && (
<p className={styles.registerWrapper}>
Don’t have an account?{' '}
<span className={styles.forgotPassword}>
<Link to="/register">Register here</Link>
</span>
</p>
)} */}
      {componentType === 'register' && (
        <p className={styles.registerWrapper}>
          Already have an account?{' '}
          <span className={styles.forgotPassword}>
            <Link to="/login">Log in</Link>
          </span>
        </p>
      )}
      {componentType === 'forgotPassword' && (
        <p className={styles.registerWrapper}>
          <span className={styles.forgotPassword}>
            <Link to="/login">Back to Login</Link>
          </span>
        </p>
      )}
    </div>
  )
}

export default Authentication
