import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { fetchInvitedLinkData } from '@services/fetchInvitedLinkData'
import { notify } from '@utils/notify'

import styles from './acceptInvite.module.css'

const AcceptInvite = () => {
  const currentURL = window.location.href
  if (
    currentURL.includes('utm_term') &&
    currentURL.includes('utm_medium') &&
    currentURL.includes('utm_content') &&
    currentURL.includes('utm_source') &&
    currentURL.includes('utm_campaign') &&
    currentURL.includes('utm_ref') &&
    currentURL.includes('utm_ref-ext')
  ) {
    const path = currentURL.split('/').slice(4).join('/')
    const queryParams = path.split('&')
    const values = {}
    queryParams.forEach(param => {
      const [key, value] = param.split('=')
      values[key] = decodeURIComponent(value)

      localStorage.setItem(key, decodeURIComponent(value))
    })
  }
  const [isLoading, setIsLoading] = useState(true)
  const { inviteToken } = useParams()
  const [serverError, setServerEror] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (inviteToken) {
      setServerEror(false)
      setError(null)
      setIsLoading(true)
      fetchInvitedLinkData(inviteToken).then(res => {
        setTimeout(() => {
          setIsLoading(false)
          if (res.status >= 500) {
            setServerEror(true)
            notify(t('notification.internalServer'), 'error')
          } else if (res.status === 406) {
            if (res?.data?.no_invitation_found) {
              notify(t('notification.invalidInvitation'), 'warning')
              setError(`${t('notification.invalidInvitationText')}`)
            } else if (res?.data?.invitation_expired) {
              notify(t('notification.invitationLinkExpired'), 'warning')
              setError(`${t('notification.invitationLinkExpiredText')}`)
            } else if (res?.data?.invitation_already_accepted && res?.data?.is_legacy_user) {
              notify(t('notification.invitationLinkAlreadyAccepted'), 'warning')
              localStorage.setItem(
                'inviteFrom',
                JSON.stringify({
                  customer_id: res?.data?.customer_id,
                  email: res?.data?.invitee_email,
                })
              )
              history.push('/login')
            } else if (!res.data.is_legacy_user) {
              notify(t('notification.welcomeToAdGraders'), 'success')
              localStorage.setItem(
                'inviteFrom',
                JSON.stringify({
                  customer_id: res?.data?.customer_id,
                  email: res?.data?.invitee_email,
                })
              )
              // history.push('/register')
              history.push({
                pathname: '/login',
                // pathname: '/register',
                state: { invitedUser: inviteToken },
              })
            }
          } else if (res.status === 201) {
            if (res.data.is_legacy_user) {
              notify(t('notification.invitationLinkAlreadyAccepted'), 'warning')
              localStorage.setItem(
                'inviteFrom',
                JSON.stringify({
                  customer_id: res?.data?.customer_id,
                  email: res?.data?.invitee_email,
                })
              )
              history.push('/login')
            } else {
              notify(t('notification.welcomeToAdGraders'), 'success')
              localStorage.setItem(
                'inviteFrom',
                JSON.stringify({
                  customer_id: res?.data?.customer_id,
                  email: res?.data?.invitee_email,
                })
              )
              // history.push('/register')
              history.push({
                pathname: '/login',
                // pathname: '/register',
                state: { invitedUser: inviteToken },
              })
            }
          }
        }, 1000)
      })
    }
    // eslint-disable-next-line
  }, [inviteToken])

  return (
    <>
      {serverError ? (
        <Typography.Title className={styles.heading}>
          Something went wrong on our end, We are working on that please be patient!
        </Typography.Title>
      ) : (
        <div>
          <Typography.Title className={styles.heading}>
            {error || t('linkValidation')}
          </Typography.Title>
          {isLoading && (
            <div className={styles.acceptInviteContainer}>
              <Spin size="large" />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default AcceptInvite
