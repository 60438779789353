export const AG_AC_TOKEN = 'adsGraderAccessToken'
export const USER = 'user'
export const LS_KEY = 'np_ads_grader:session'
export const UPDATE_USER = 'updateUser'
export const UPDATE_CUSTOMER = 'updateCustomer'
export const UPDATE_REPORT = 'updateReport'
export const LOGOUT_USER = 'logoutUser'
export const UPDATE_ROLE = 'role'
export const UPDATE_SAMPLE_REPORT = 'sampleReport'
export const UPDATE_REPORT_DASHBOARD = 'reportDashboardReducer'
export const UPDATE_ERROR_CUSTOMER = 'errorCustomer'
export const UPDATE_GENERATE_REPORT = 'generateReport'
export const UPDATE_CURRENT_REPORT = 'currentReport'
export const UPDATE_LEARNING_SEARCH_STRING = 'learningCenterSearch'
export const UPDATE_LEARNING_SELECTED_VALUE = 'learningCenterSelect'
export const SELECTED_ID = 'selectedId'
export const MANAGE_USERS_TAB = 'manageUsersTab'
export const ACCOUNT_TAB = 'accountTab'
export const PLATFORM_INTEGRATIONS = 'platformIntegrations'
export const ADS_PERFORMANCE_GRADE = 'adsperformancegrade'
export const PERFORMANCE_SECTION = 'performanceSection'
export const COST_EVALUATION_SECTION = 'costEvaluationSection'
export const WASTED_SPEND_SECTION = 'wastedSpendSection'
export const AD_RELEVANCE_SECTION = 'adRelevanceSection'
export const QUALITY_SCORE_SECTION = 'qualityScoreSection'
export const CLICK_THROUGH_RATE_SECTION = 'clickThroughRateSection'
export const KEYWORDS_MATCH_TYPE_SECTION = 'keywordsMatchTypeSection'
export const IMPRESSION_SHARE_SECTION = 'impressionShareSection'
export const GOOGLE_ADS_BEST_PRACTICES_SECTION = 'googleAdsBestPracticesSection'
export const SHARE_WITH_TEAM_SECTION = 'shareWithTeamSection'
export const ADS_BEDGET_COST_CACULATOR = 'adsBudgetCostCalculator'
export const AG_ALERTS = 'agAlert'
export const ADS_SEARCH_COMPANY = 'adsPreviewSearchCompany'
export const ADS_SEARCH_KEYWORD = 'adsPreviewSearchKeyword'
export const ADS_START_DATE = 'adsPreviewStartDate'
export const ADS_END_DATE = 'adsPreviewEndDate'
export const ADS_LOCATION = 'adsPreviewLocation'
export const ADS_CHANNEL = 'adsPreviewChannel'
export const ADS_FORMAT = 'adsPreviewFormat'
export const ADS_DATA = 'adsPreviewData'
export const ADS_PAGINATION_DATA = 'adsPaginationData'
export const EMPTY_ADS_PREVIEW_DATA = 'emptyAdsPreviewData'
export const EMPTY_ADS_PAGINATION_DATA = 'emptyPaginationData'
export const ADS_LOADER = 'adsPreviewLoader'
export const ADS_HOME_STATE = 'adsHomeState'
export const ADS_NO_DATA = 'adsNoData'
export const ADS_PAGINATION = 'adsPagination'
export const ADS_MODAL_PAGINATION = 'adsModalPagination'
export const LEARNING_CENTER = 'learningCenter'
export const LEARNING_CENTER_MESSAGE_FROM_MODAL = 'learningCenterMessageFromModal'
export const LEARNING_CENTER_API_DATA = 'learningCenterApiData'
export const LEAD_DATA = 'leadData'
export const UPDATE_ACTIVE_BUTTON_STATE = 'update_active_button_state'
export const FILTERED_DATA_LENGTH = 'filtered_data_length'
export const HAS_MORE = 'has_more'
export const PAGE_COUNT = 'page_count'
export const PAGE_COUNT_RESET = 'page_count_reset'
export const SPINNER = 'spinner'
export const CLEANED_DATA_COUNT = 'cleaned_data_count'
export const CURRENT_REPORT_ID = 'current_report_id'
export const MORE_DATA_LOADER = 'moreDataLoader'
export const META_ADS_PAGINATION_DATA = 'meta_ads_pagination_data'
export const WEEKLY_REPORT = 'weeklyReport'
export const REPORT_DIFFERENCE = ' reportDifference'
export const SINGLE_REPORT = 'singleReport'
export const REPORT_INDEX = 'reportIndex'
export const ALL_REPORTS = 'allReports'
export const PAGINATED_REPORTS = 'paginatedreports'
export const PAGINATED_NEXT = 'paginatedNext'
export const REPORT_CURRENT_ID = 'report_current_id'
export const EXTRA_INSIGHTS_CLICK = 'extra_insights_click'
export const EXTRA_INSIGHTS_DATA = 'extra_insights_data'
