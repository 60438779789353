import React, { useState } from 'react'
import { Text } from '@components'
import searchResultImage from '../../../../images/AdsPreview/searchResultImage.png'
import styles from './searchResultsError.module.css'

const SearchResultsError = () => {
  const [temp, setTemp] = useState()
  return (
    <>
      <div style={{ justifyContent: 'center' }}>
        <div style={{ marginTop: '104px', textAlign: 'center' }}>
          <img src={searchResultImage} alt="calender.png" />
        </div>
        <Text size={18} isBold style={{ marginTop: 16, textAlign: 'center' }}>
          No results found
        </Text>
        <Text
          size={16}
          style={{
            marginTop: 16,
            color: '#67696B',
            width: '292px',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          Try refining your search criteria or modify the filters.
        </Text>
      </div>
    </>
  )
}
export default SearchResultsError
