import React, { useState } from 'react'
import { Typography } from 'antd'

import styles from './platformIntegrations.module.scss'

const { Title, Text } = Typography

const PlatformIntegrations = () => {
  // console.log('platformIntegrations')
  const [temp, setTemp] = useState()
  return (
    <>
      <div className={styles.container}>
        <Title level={3}>Installed Platforms</Title>
      </div>
    </>
  )
}

export default PlatformIntegrations
