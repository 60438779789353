import { CloseOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import Text from '@components/Text/text'
import shareIcon from '@images/AdsPreview/shareIcon.png'
import React, { useState } from 'react'
import ModalCard from '../ModalCard'
import ModalAboutTable from '../ModalAboutTable'
import style from './adsModal.module.css'

const AdsModal = ({ modalOpen, handleOk, handleCancel, adsData }) => {
  const [isModalOpen, setIsModalOpen] = useState(modalOpen)

  const showModal = () => {
    setIsModalOpen(true)
  }

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width="100%"
      footer={null}
      borderRadius="12px 12px 0px 0px"
    >
      <div className={style.crossButton}>
        <button
          type="button"
          style={{
            border: 'none',
            background: 'none',
            margin: '11.37px',
            fontWeight: 'bold',
            fontSize: '18px',
          }}
          onClick={handleCancel}
        >
          {/* <img src={cancelIcon} alt="cancel-icon" style={{ cursor: 'pointer', color: 'black' }} /> */}
          <CloseOutlined className={style.closeButton} />
        </button>
      </div>
      <div className={style.modalContainer}>
        <div className={style.modalTitle}>
          <Text size={24} isBold style={{ color: '#26282C' }}>
            Ad Details
          </Text>
        </div>
        <Row style={{ marginTop: 38 }} gutter={24}>
          {/* Left Part of Modal */}
          <Col span={9}>
            <ModalCard adsData={adsData} />
          </Col>
          {/* Right Part of Modal */}
          <Col span={15}>
            <ModalAboutTable adsData={adsData} />
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default AdsModal
