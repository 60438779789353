/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react'
import { Col, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { formatDate } from 'utils/helperFunctions'
import Text from 'components/Text/text'
import { CURRENT_REPORT_ID, EXTRA_INSIGHTS_CLICK } from '@utils/constants'
import { RequestService } from 'services/requests'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  allReports,
  paginatedNext,
  reportCurrentId,
  singleReport,
  updateReport,
} from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'

import styles from './reportHeader.module.scss'

const ReportHeader = ({ reports, currentReport, setCurrentReport, isNavbar }) => {
  const [copiedReports, setCopiedReports] = useState(reports)
  const next = useSelector(state => state?.weeklyReport?.next)
  const [customerId, setCustomerId] = useState()
  const [page, setPage] = useState(0)
  const [prevNext, setPrevNext] = useState(0)
  useEffect(() => {
    if (next) {
      try {
        const urlObj = new URL(next)
        setCustomerId(urlObj.searchParams.get('customer_id'))
        setPage(urlObj.searchParams.get('page'))
      } catch (error) {
        console.error('Invalid URL:', error)
      }
    }
    // eslint-disable-next-line
  }, [next])
  const currentReports = useSelector(state => state.weeklyReport.singleReport)
  const currentIndex = useSelector(state => state.weeklyReport.index)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentReport) {
      dispatch({ type: CURRENT_REPORT_ID, payload: currentReport?.id })
      dispatch(reportCurrentId(currentReport?.id))
    }
    // eslint-disable-next-line
  }, [currentReport])
  useEffect(() => {
    if (copiedReports) {
      dispatch(allReports(copiedReports))
    }
    // eslint-disable-next-line
  }, [copiedReports])

  const [currentReportIndex, setCurrentReportIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const currentReportId = useSelector(state => state?.role?.currentReportId)

  const { t } = useTranslation()
  const history = useHistory()
  useEffect(() => {
    if (currentReports && currentIndex) {
      setCurrentReport(currentReports)
      setCurrentReportIndex(currentIndex)
    }
    // eslint-disable-next-line
  }, [currentReports, currentIndex])
  const handleReportSelect = reportId => {
    dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: true })
    dispatch(singleReport(''))
    const report = copiedReports.find(item => item.id === reportId)
    const index = copiedReports.findIndex(item => item.id === reportId)
    setCurrentReportIndex(index)
    setCurrentReport(report)
    history.push('/dashboard')
  }
  const handlePrevButtonClick = () => {
    dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: true })
    if (currentReportIndex !== copiedReports.length - 1) {
      setCurrentReportIndex(currentReportIndex + 1)
      setCurrentReport(copiedReports[currentReportIndex + 1])
      history.push('/dashboard')
    }
  }
  const handleNextButtonClick = () => {
    dispatch({ type: EXTRA_INSIGHTS_CLICK, payload: true })
    if (currentReportIndex !== 0) {
      setCurrentReportIndex(currentReportIndex - 1)
      setCurrentReport(copiedReports[currentReportIndex - 1])
      history.push('/dashboard')
    }
  }
  const handlePopupScroll = async e => {
    const { target } = e
    const buffer = 20 // Adjust buffer size as needed

    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - buffer) {
      if (prevNext !== null) {
        try {
          const res = await RequestService.get(
            `inviter-customer-reports/?customer_id=${customerId}&page=${page}`
          )
          setPrevNext(res?.data?.next)

          const mergedReports = [...copiedReports, ...res?.data?.results] // Merge the existing and new reports
          // setCurrentReport(mergedReports)
          setCopiedReports(mergedReports)
          if (res?.data?.next) {
            const urlObj = new URL(res.data.next)
            setPage(urlObj.searchParams.get('page'))
          }
          // setPage(res.next)
        } catch (error) {
          console.log('error', e)
        }
      }
    }
  }
  return (
    <>
      {/* {!isNavbar && (
        <div className={styles.headerContainer}>
          <div className={styles.headerLogoSection}>
            <Text isTitle isBold color="white">
              {t('npDigial')}
            </Text>
            {!!currentReport?.id && (
              <Text color="white" isSemibold>
                {t('id')} : {currentReport?.id}
              </Text>
            )}
          </div>
        </div>
      )} */}
      {isNavbar && (
        <Row gutter={6} align="middle" className={styles?.reportsList}>
          {/* <Col span={4}>
            <Text color="white" size="14px" isSemibold>
              {t('reportText')}
            </Text>
          </Col> */}
          <Col span={20}>
            <Select
              value={
                currentReports
                  ? dayjs(currentReports?.created_at).format('ddd, MMM DD, YYYY, h:mm A')
                  : currentReportId ?? currentReport?.id
              }
              onChange={handleReportSelect}
              open={isOpen}
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
              className={styles.capitalize}
              style={{ width: '100%', maxWidth: '358px' }}
              onPopupScroll={handlePopupScroll}
            >
              {copiedReports?.map(report => {
                const [_, month, date, year, time, _format] = dayjs(report?.created_at)
                  .format('ddd, MMM DD, YYYY, h:mm A')
                  .split(' ')
                const [day] = dayjs(report?.created_at)
                  .format('ddd, MMM DD, YYYY, h:mm A')
                  .split(',')
                return (
                  <Select.Option key={report?.id} value={report?.id} className={styles.capitalize}>
                    {t(day)}, {t(month)} {date} {year} {time} {_format}
                  </Select.Option>
                )
              })}
            </Select>
          </Col>
          <Row gutter={6}>
            <Col span={12}>
              <LeftOutlined
                onClick={handlePrevButtonClick}
                className={`${styles.navigationIcon} ${
                  (currentReportIndex === copiedReports.length - 1 || copiedReports?.length < 2) &&
                  styles.disabled
                }`}
              />
            </Col>
            <Col span={12}>
              <RightOutlined
                onClick={handleNextButtonClick}
                className={`${styles.navigationIcon} ${
                  (currentReportIndex === 0 || copiedReports?.length < 2) && styles.disabled
                }`}
              />
            </Col>
          </Row>
        </Row>
      )}
    </>
  )
}

export default ReportHeader
