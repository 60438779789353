import React, { useState } from 'react'
import AdsPreviewFeature from './ads-preview-feature'

const AdsPreview = ({ fetchData, adsPreviewData }) => {
  const [temp, setTemp] = useState()
  return (
    <div id="scrollDiv">
      <div style={{ marginTop: 40, marginLeft: 52, marginRight: 52 }}>
        <AdsPreviewFeature fetchData={fetchData} adsPreviewData={adsPreviewData} />
      </div>
    </div>
  )
}

export default AdsPreview
